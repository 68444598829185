import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table", "mapping_dropdown",
    "filter_period", "filter_transaction_type",
    "filter_purpose", "filter_account", "filter_pay_type"]

  connect() {
    if (this.hasIndex_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }


  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = []

    if (this.hasFilter_periodTarget) {
      filters.push({
        field: "start_at_end_at_period",
        type: "=",
        value: this.filter_periodTarget.value
      })
    }

    if (this.hasFilter_transaction_typeTarget) {
      filters.push({
        field: "transaction_type_ids",
        type: "=",
        value: this.filter_transaction_typeTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_purposeTarget) {
      filters.push({
        field: "purpose_ids",
        type: "=",
        value: this.filter_purposeTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_accountTarget) {
      filters.push({
        field: "account_ids",
        type: "=",
        value: this.filter_accountTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_pay_typeTarget) {
      filters.push({
        field: "pay_type_ids",
        type: "=",
        value: this.filter_pay_typeTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.index_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    const footer_labels = JSON.parse(this.data.get("footer-labels"));

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 250) + "px",
      persistenceID: "employee_transactions_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // headerSort: false,
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      footerElement: "<table class='table table-striped table-heading-fixed' id='cashbox_transactions_totals_table'> \
          <tfoot> \
            <tr>  \
              <td class='text-right' style='width: 30%'> \
                <strong class='font-size-16'>" + footer_labels['total_label'] + "</strong> \
              </td> \
              <td class='text-right td-w-100'/> \
              <td class='text-right td-w-60'> \
                <strong class='font-size-16'>" + footer_labels['incomes_label'] + "</strong> \
              </td> \
              <td class='text-right td-w-100 totals_incomes'/> \
              <td class='text-right td-w-60'> \
                <strong class='font-size-16'>" + footer_labels['expenses_label'] + "</strong> \
              </td> \
              <td class='text-right td-w-100 totals_expenses'/> \
              <td class='text-right' style='width: 20%'/>  \
            </tr>  \
          </tfoot> \
        </table>",
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.cashbox_transaction__status) {
          row.getElement().classList.add("row-" + data.cashbox_transaction__status)
        }
      },
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("cashbox_transactions_totals_table")
        table.querySelector(".totals_incomes").innerHTML = response.total_incomes;
        table.querySelector(".totals_expenses").innerHTML = response.total_expenses;

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


}