import Tabulator from 'tabulator-tables';
import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";
import { Draggable } from 'agnostic-draggable';

export default class extends ApplicationController {
  static targets = ["index_table", "working_times_modal_lg", "working_times_modal_lg_second",
  "modal_holidays_row_number", "holidays_table", "holiday_row_template", "working_time_main_form",
  "working_time_name", "working_time_percent"]

  connect() {
    if (this.hasIndex_tableTarget) {
      let data = this.data.get("table-datarows");
      let table = this.createIndexTable();
      table.setData(data)
    }

    if (this.hasWorking_times_modal_lgTarget) {
      window.working_times_modal_lg = new BSN.Modal(this.working_times_modal_lgTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasWorking_times_modal_lg_secondTarget) {
      window.working_times_modal_lg_second = new BSN.Modal(this.working_times_modal_lg_secondTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasWorking_time_main_formTarget) {
      this.addReloadWorkingTimeModalTrigger(this.working_time_main_formTarget);
    }

  }

  createIndexTable() {
    const columns = this.data.get("table-columns");
    let table = new Tabulator(this.index_tableTarget, {
      columns: JSON.parse(columns),
      height: "100%",
      // dataTree: true,
      // dataTreeStartExpanded: true,
      layout: "fitColumns",
      resizableRows: true
    });

    return table;
  }

  addReloadWorkingTimeModalTrigger(form_element) {
    let controller;
    if (form_element) controller = this.formController(form_element)
    if (controller) {
      const wt_reload_url_field = document.querySelector("#working_times_form_reload_url");
      if (wt_reload_url_field) {
        wt_reload_url_field.value = "";
        wt_reload_url_field.addEventListener(
          "change",
          function (event) {
            let url = event.currentTarget.value;
            if (url && window.working_times_modal_lg) {
              controller.setModalContent(window.working_times_modal_lg, url)
            }
          }, false,
        );
      }
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "payments--workingTimes")
  }

  openWorkingTimeModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.working_times_modal_lg) {
      this.setModalContent(window.working_times_modal_lg, url)
      super.draggable_modal('#working_times_modal_lg .modal-content');
    }
  }

  openWorkingTimeHolidaysModal(event){
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (this.hasWorking_time_nameTarget){
      url += (url.split('?')[1] ? '&':'?') + "working_time_name=" + this.working_time_nameTarget.value;
    }
    if (this.hasWorking_time_percentTarget){
      url += (url.split('?')[1] ? '&':'?') + "working_time_percent=" + this.working_time_percentTarget.value;
    }

    if (window.working_times_modal_lg_second) {
      this.setModalContent(window.working_times_modal_lg_second, url)
      super.draggable_modal('#working_times_modal_lg_second .modal-content');
      this.hideWorkingTimeModal();
      this.hideHolidaysModal();
    }
  }



  async setModalContent(modal, url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (modal) {
      this.removeBackdrops();
      modal.setContent(content);
      modal.show();
      modal.update();
    }

  }

  removeBackdrops() {
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove every modal backdrop
    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  }

  hideWorkingTimeModal() {
    if (window.working_times_modal_lg) {
      window.working_times_modal_lg.hide();
      window.working_times_modal_lg.update();
    }
  }

  hideHolidaysModal() {
    if (window.working_times_modal_lg_second) {
      window.working_times_modal_lg_second.hide();
      window.working_times_modal_lg_second.setContent("");
      window.working_times_modal_lg_second.update();
    }
  }

  showWorkingTimeModal(){
    if (window.working_times_modal_lg) {
      this.hideWorkingTimeModal()
      this.hideHolidaysModal()
      this.removeBackdrops();
      window.working_times_modal_lg.toggle();
      window.working_times_modal_lg.update();
    }
  }

  toggleIsPayedHoliday(event){
    const tr = event.currentTarget.closest("tr");
    const paid_hour_field = tr.querySelector("input.holiday_paid_hours_field");

    if (paid_hour_field) {
      if (!event.currentTarget.checked) {
        paid_hour_field.classList.remove("is-invalid");
        paid_hour_field.disabled = true;
      } else {
        paid_hour_field.disabled = false;
      }
    }

  }


  addHolidayRow(event){
    event.preventDefault();

    let cell = this.holidays_tableTarget.insertRow();
    let new_row  = this.holiday_row_templateTarget.innerHTML;
    let new_index = parseInt(this.holidays_tableTarget.getAttribute("data-rows_count"));

    new_row = new_row.replace(/NEW_HOLIDAY_ROW_INDEX/g, new_index);

    cell.innerHTML = new_row;
    cell.setAttribute("data-row_index", new_index);
    this.holidays_tableTarget.setAttribute("data-rows_count", new_index + 1)

    this.setHolidaysRowsNumbers();
  }

  removeHolidayRow(event) {
    event.preventDefault();
    const tr = event.currentTarget.closest("tr");
    const tr_index = tr.getAttribute("data-row_index");
    let destroy_key = document.getElementById("working_times_year_group_working_times_holidays_attributes_" + tr_index + "__destroy");
    if (destroy_key)
      destroy_key.value = "true";
    tr.remove();

    this.setHolidaysRowsNumbers();
  }

  setHolidaysRowsNumbers(){
    this.modal_holidays_row_numberTargets.forEach((el, index) => {
      el.innerHTML = index + 1;
    })
  }

  toggleIsFixedHours(event){
    const tr = event.currentTarget.closest("tr");
    const fixed_hours_field = tr.querySelector("input.fixed_hours_field");

    if (fixed_hours_field) {
      if (!event.currentTarget.checked) {
        fixed_hours_field.classList.remove("is-invalid");
        fixed_hours_field.disabled = true;
      } else {
        fixed_hours_field.disabled = false;
      }
      this.recalculateWorkingHours(event.currentTarget);
    }
  }

  onFixedHoursFieldChange(event){
    event.preventDefault();
    this.recalculateWorkingHours(event.currentTarget);
  }


  recalculateWorkingHours(target) {
    const tr = target.closest("tr");
    const tr_index = tr.getAttribute("data-row_index");
    const is_fixed_field = tr.querySelector("input.is_fixed_field");
    const fixed_hours_field = tr.querySelector("input.fixed_hours_field");

    const hours_per_day_field = tr.querySelector("td.hours_per_day_field");
    const hours_per_week_field = tr.querySelector("td.hours_per_week_field");
    const hours_per_month_field = tr.querySelector("td.hours_per_month_field");

    const calculated_hours_per_month_field = document.getElementById("calculated_hours_per_month_field_" + tr_index);
    const setting_hours_per_day_field = document.getElementById("setting_hours_per_day_field_" + tr_index);
    const setting_days_per_week_field = document.getElementById("setting_days_per_week_field_" + tr_index);


    if (is_fixed_field && fixed_hours_field) {
      if (is_fixed_field.checked) {
        hours_per_day_field.innerText   = fixed_hours_field.value / 4.0 / 5.0;
        hours_per_week_field.innerText  = fixed_hours_field.value / 4.0;
        hours_per_month_field.innerText = fixed_hours_field.value;
      } else {
        hours_per_day_field.innerText   = setting_hours_per_day_field.value;
        hours_per_week_field.innerText  = setting_hours_per_day_field.value * setting_days_per_week_field.value;
        hours_per_month_field.innerText = calculated_hours_per_month_field.value;
      }
    }
  }

}
