import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["table",
    "search", "bussines_model_ids", "partner_status_ids", "partner_owner_ids",
    "partners_responsible_ids",

    "mapping_dropdown", "mapping_checkbox", "mapping_fields",

    "sidebar_table", "sidebar_search", "sidebar_partner_status_ids",
    "sidebar_partner_owner_ids", "sidebar_partner_responsible_ids",
    "sidebar_bussines_model_ids", "sidebar_current_sort", "sidebar_sort",
    "sidebar_current_filter", "sidebar_filter", "group_edit_button",
    "legal_group_edit_table",

    "payroll_division_template",
    "payroll_divisions_tbody",
    "payroll_divisions_table"
  ]

  connect() {
    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }
  }

  //
  // Actions
  //

  addNewDivisionRow(event) {
    event.preventDefault();
    const template_row = this.payroll_division_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.payroll_divisions_tbodyTarget.insertAdjacentHTML('beforeend', template_row)
    this.reindexFormTableRows()
  }

  removeNewDivisionRow(event) {
    event.preventDefault();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      tr.remove();
      this.reindexFormTableRows()
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  //
  // Private
  //

  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = this.payroll_divisions_tableTarget
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }


  // create sidebar
  async createSidebarTable(url) {
    const columns = this.data.get("table-columns");
    const per_page = this.data.get("per-page");
    const table_target = this.sidebar_tableTarget;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "payrolls_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        // if (data.status) row.getElement().classList.add("row-" + data.status)
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);

    // this.setSidebarCurrentFilter();
  }

  // Show payroll
  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }


}