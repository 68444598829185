import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";



Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "bonusSummaryTotalsText": function (values, data, calcParams) {
    const table = document.getElementById("bonuses_summary_table")
    if (table)
      return table.getAttribute("data-total-text")
  },

  "bonusDetailsTotalsText": function (values, data, calcParams) {
    const table = document.getElementById("bonuses_details_table")
    if (table)
      return table.getAttribute("data-total-text")
  },

  "bonusSummaryTotalsBonusAmount": function (values, data, calcParams) {
    const table = document.getElementById("bonuses_summary_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total");
    return total_html;
  },

  "bonusDetailsTotalsBonusAmount": function (values, data, calcParams) {
    const table = document.getElementById("bonuses_details_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total");
    return total_html;
  },
});


export default class extends ApplicationController {
  static targets = ["details_table", "summary_table",
    "period_filter", "deal_filters", "partner_filters", "user_filters"]


  connect() {
    if (this.hasDetails_tableTarget) {
      const details_table_url = this.details_tableTarget.getAttribute("data-table-load-url");
      this.createDetailsTable(details_table_url);
    }
    if (this.hasSummary_tableTarget) {
      const summary_table_url = this.summary_tableTarget.getAttribute("data-table-load-url");
      this.createSummaryTable(summary_table_url);
    }
  }

  filterTable() {
    this.setFilterToTable();
  }

  onIsConfimedChange(event){
    event.currentTarget.disabled = true;
    let url = event.currentTarget.getAttribute("data-url");
    let data = { "bonuses_payment": { "is_confirmed": event.currentTarget.checked ? "true" : "false" }}
    this.updateIsConfirmedStatus(url, data, event.currentTarget);
  }

  setFilterToTable() {
    let details_table = this.details_tableTarget.tabulator;
    let summary_table = this.summary_tableTarget.tabulator;
    let filters = this.getPageFilters();

    if (filters.length) {
      details_table.setFilter(filters);
      summary_table.setFilter(filters);
    }
  }

  getPageFilters() {
    let filters = []

    if (this.hasPeriod_filterTarget) {
      filters.push({
        field: "period",
        type: "=",
        value: this.period_filterTarget.value
      })
    }

    if (this.hasDeal_filtersTarget) {
      filters.push({
        field: "deals_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.deal_filtersTarget)
      })
    }

    if (this.hasPartner_filtersTarget) {
      filters.push({
        field: "partners_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.partner_filtersTarget)
      })
    }

    if (this.hasUser_filtersTarget) {
      filters.push({
        field: "users_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.user_filtersTarget)
      })
    }

    return filters;
  }


  async updateIsConfirmedStatus(url, data, element) {
    fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          content = JSON.parse(content);

          window.vNotify.success({ text: content.notification_message, title: content.notification_title });
          this.updateTable(content.id, content.data, null);
        })
      } else {
        response.text().then((content) => {
          content = JSON.parse(content);
          window.vNotify.error({ text: content.notification_message, title: content.notification_title });

          element.disabled = false;
          element.checked = !element.checked;
        })
      }
    });
  }

  async updateTable(rowId, rowData, total) {
    const mainTable = document.getElementById("bonuses_details_table");
    const mainTabulator = mainTable.tabulator;

    mainTabulator.updateRow(rowId, rowData);
  }


  async createSummaryTable(url) {
    const table_target = this.summary_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "bonuses_summary_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 250) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      pagination: "remote",
      initialFilter: this.getPageFilters(),
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      ajaxResponse:function(url, params, response){
        const table = document.getElementById("bonuses_summary_table")
        table.setAttribute("data-total", response.total);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }



  async createDetailsTable(url) {
    const table_target = this.details_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "bonuses_details_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 250) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      initialFilter: this.getPageFilters(),
      pagination: "remote",
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      rowFormatter: function (row) {
        const data = row.getData();
        if (data.status_color) {
          row.getElement().className = row.getElement().className.replace(/(\s|^)row-\S+/g, '');
          row.getElement().classList.add("row-" + data.status_color);
        }
      },
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("bonuses_details_table")
        table.setAttribute("data-total", response.total);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else if (target.getAttribute("data-selected")) {
      return JSON.parse(target.getAttribute("data-selected"));
    } else if (target.getAttribute("data-current-id")) {
      return target.getAttribute("data-current-id").split(",");
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

}
