import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["table",
    "search", "bussines_model_ids", "partner_status_ids", "partner_owner_ids",
    "partners_responsible_ids",

    "mapping_dropdown", "mapping_checkbox", "mapping_fields",

    "sidebar_table", "sidebar_search", "sidebar_partner_status_ids",
    "sidebar_partner_owner_ids", "sidebar_partner_responsible_ids",
    "sidebar_bussines_model_ids", "sidebar_current_sort", "sidebar_sort",
    "sidebar_current_filter", "sidebar_filter", "group_edit_button",
    "legal_group_edit_table"]

  connect() {
    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }

    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }

    if (this.hasLegal_group_edit_tableTarget) {
      this.createEditListTable();
    }

  }

  //
  // Actions
  //

  openImportForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  newPartner(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  editPartner(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  groupEditPartner(event) {
    event.preventDefault();
    const tabulator = this.tableTarget.tabulator;
    // const selectedRows = tabulator.getSelectedRows();
    const selectedData = tabulator.getSelectedData();
    if (selectedData.length > 0) {
      let ids = []
      for (let i = 0; i < selectedData.length; i++) {
        let row = selectedData[i];
        if (Array.isArray(row.id)) ids.push(row.id[0])
        else ids.push(row.id)
      }
      const url = event.currentTarget.getAttribute("data-url");
      this.openGruopLayoutModalXL(url, ids);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let sidebar_table = this.sidebar_tableTarget.tabulator;

    // change sort dir
    if (sort_dir == "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    sidebar_table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
      // if (sort_target.querySelector("i")) sort_target.querySelector("i").remove();
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this.getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // gen icon html
  getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir == "asc") up_or_down = "down"
    const html = column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>"
    return html;
  }

  // Sidebar Filter
  setSidebarFilter() {
    this.setSidebarFilterToTable();
  }
  //
  // table filters action
  //
  filterTable(event) {
    if (event.currentTarget.value.length == 0) this.setFilterToTable();
    if ( event.currentTarget.value.length < 3 ) return;
    this.setFilterToTable();
  }

  //
  //            PRIVATE
  //

  setSidebarCurrentFilter() {
    let bussines_model_texts = ""
    let partner_status_texts = ""
    let owner_texts = ""
    let responsible_texts = ""

    if (this.hasSidebar_bussines_model_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_bussines_model_idsTarget);
      const fieldContainer = this.sidebar_bussines_model_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) bussines_model_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_partner_status_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_partner_status_idsTarget);
      const fieldContainer = this.sidebar_partner_status_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) partner_status_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_partner_owner_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_partner_owner_idsTarget);
      const fieldContainer = this.sidebar_partner_owner_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) owner_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_partner_responsible_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_partner_responsible_idsTarget);
      const fieldContainer = this.sidebar_partner_responsible_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) responsible_texts = column_name + ": &nbsp;" + items + "<br/>"
    }
    const current_filter = this.sidebar_current_filterTarget;
    current_filter.innerHTML = bussines_model_texts + partner_status_texts + owner_texts + responsible_texts;
  }

  // Sidebar Table Filters
  setSidebarFilterToTable() {
    let filters = this.getSidebarPageFilters();

    if (filters.length) {
      let sidebar_table = this.sidebar_tableTarget.tabulator;

      sidebar_table.setFilter(filters);
      this.setSidebarCurrentFilter();
      // close drop down
      if (this.hasSidebar_filterTarget) {
        this.sidebar_filterTarget.classList.remove('show');
      }

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  // Main table filters
  setFilterToTable() {
    let filters = this.getPageFilters();

    if (filters.length) {
      let table = this.tableTarget.tabulator;
      table.setFilter(filters);

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasBussines_model_idsTarget) {
      filters.push({
        field: "bussines_model_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.bussines_model_idsTarget)
      })
    }

    if (this.hasPartner_status_idsTarget) {
      filters.push({
        field: "partner_status_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.partner_status_idsTarget)
      })
    }

    if (this.hasPartner_owner_idsTarget) {
      filters.push({
        field: "partner_owner_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.partner_owner_idsTarget)
      })
    }

    if (this.hasPartners_responsible_idsTarget) {
      filters.push({
        field: "partners_responsible_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.partners_responsible_idsTarget)
      })
    }
    console.log(filters);
    return filters;
  }

  getSidebarPageFilters() {
    let filters = []

    if (this.hasSidebar_searchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.sidebar_searchTarget.value
      })
    }

    if (this.hasSidebar_bussines_model_idsTarget) {
      filters.push({
        field: "bussines_model_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_bussines_model_idsTarget)
      })
    }

    if (this.hasSidebar_partner_status_idsTarget) {
      filters.push({
        field: "partner_status_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_partner_status_idsTarget)
      })
    }

    if (this.hasSidebar_partner_owner_idsTarget) {
      filters.push({
        field: "partner_owner_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_partner_owner_idsTarget)
      })
    }

    if (this.hasSidebar_partner_responsible_idsTarget) {
      filters.push({
        field: "partners_responsible_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_partner_responsible_idsTarget)
      })
    }

    return filters;
  }

  // Show Partner
  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }

  // create sidebar
  async createSidebarTable(url) {
    const columns = this.data.get("table-columns");
    const per_page = this.data.get("per-page");
    const table_target = this.sidebar_tableTarget;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "legal_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getSidebarPageFilters(),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      groupBy: "partners__group_name",
      groupToggleElement: "header",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.status) row.getElement().classList.add("row-" + data.status)
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);

    this.setSidebarCurrentFilter();
  }



  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;
    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    let edit_button;
    if (this.hasGroup_edit_buttonTarget)
      edit_button = this.group_edit_buttonTarget;

    let addContacts = this.addContactsSubTable
    let contact_columns = this.contactsColumns()
    contact_columns = contact_columns[super.appLocale]
    // console.log(contact_columns)

    let table_props = {
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      persistenceID: "legal_main_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),

      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getPageFilters(),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      // groupBy: "partners__group_name",
      // groupToggleElement: "header",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.partners__status) {
          row.getElement().classList.add("row-" + data.partners__status)
        }
        //
        // add contacts sub table
        //
        addContacts(row, contact_columns);
      },
      rowSelectionChanged: function (data, rows) {
        if (edit_button) {
          if (data.length > 0) edit_button.classList.remove("disabled")
          else edit_button.classList.add("disabled")
        }
      },
      renderComplete: function () {
        const headerIcon = document.getElementById("legal_show_all_contacts")
        if (headerIcon.classList.contains("fa-minus-square")) {
          headerIcon.classList.toggle("fa-plus-square");
          headerIcon.classList.toggle("fa-minus-square");
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    this.addContactsShowColumn(table_target.tabulator);
    table_target.tabulator.setLocale(super.appLocale);
  }

  createEditListTable() {
    const table_target = this.legal_group_edit_tableTarget;
    const tabledata = JSON.parse(this.data.get("table-data"));
    const columns = JSON.parse(this.data.get("table-columns"));
    let table_props = {
      columns: columns,
      data: tabledata,
      layout: "fitDataFill",
      resizableColumns: false,
      headerSort: false,
      height: "600px"
    }
    table_target.tabulator = new Tabulator(table_target, table_props);
    // table_target.tabulator.setLocale(super.appLocale);
  }

  addContactsSubTable(row, contact_columns) {
    //create and style holder elements
    let holderEl = document.createElement("div");
    let tableEl = document.createElement("div");

    const id = row.getData().id;

    // holderEl.setAttribute('class', "subTable" + id + "");
    holderEl.classList.add("subTable" + id + "");
    holderEl.classList.add("contacts_sub_table");
    holderEl.classList.add("hidden");
    tableEl.classList.add("subTable" + id + "");
    holderEl.appendChild(tableEl);
    row.getElement().appendChild(holderEl);

    const subTable = new Tabulator(tableEl, {
      layout: "fitColumns",
      data: row.getData().contacts,
      columns: contact_columns
    })
  }

  addContactsShowColumn(table) {
    let showHideContacts = this.toggleContacts;
    table.addColumn({
      formatter: this.contactsIcon, hozAlign: "left", title: "<i id='legal_show_all_contacts' class='cpointer far fa-plus-square font-info'></i>", headerSort: false,
      cellClick: function (e, cell) {
        const cell_element = cell.getElement();
        const icon = cell_element.querySelector('i');
        const row = cell.getRow();
        const id = row.getData().id;

        let showOrHide = "show"
        if (icon.classList.contains("fa-plus-square")) {
          showOrHide = "show"
        } else if (icon.classList.contains("fa-minus-square")) {
          showOrHide = "hide"
        }

        showHideContacts(id, icon, showOrHide);
      },
      headerClick: function (e, column) {
        const cells = column.getCells();
        const headerIcon = document.getElementById("legal_show_all_contacts")

        let showOrHide = "show"
        if (headerIcon.classList.contains("fa-plus-square")) {
          showOrHide = "show"
        } else if (headerIcon.classList.contains("fa-minus-square")) {
          showOrHide = "hide"
        }
        headerIcon.classList.toggle("fa-plus-square");
        headerIcon.classList.toggle("fa-minus-square");
        cells.forEach(cell => {
          const cell_element = cell.getElement();
          const icon = cell_element.querySelector('i');
          const row = cell.getRow();
          const id = row.getData().id;
          showHideContacts(id, icon, showOrHide);
        });

      },
      width: 10
    }, true, "index")
  }

  toggleContacts(id, icon_element, showOrHide) {
    const sub_table = document.querySelector(".subTable" + id);
    if (sub_table !== null) {
      if (showOrHide == "hide") {
        sub_table.classList.add("hidden");
        icon_element.classList.add("fa-plus-square");
        icon_element.classList.remove("fa-minus-square");
      } else {
        sub_table.classList.remove("hidden");
        icon_element.classList.remove("fa-plus-square");
        icon_element.classList.add("fa-minus-square");
      }
    }
  }

  contactsIcon(cell, formatterParams) {
    return "<i class='far fa-plus-square font-info'></i>";
  }



  contactsColumns() {
    const columns = {
      "ru": [{
        title: "Ф.И.О",
        field: "full_name",
        headerSort: true,
        width: 250,
      },
      {
        title: "Email",
        field: "email",
        headerSort: true,
        width: 200
      },
      {
        title: "Телефон",
        field: "phone",
        headerSort: true,
        width: 150
      },
      {
        title: "Должность",
        field: "position",
        headerSort: true,
        width: 200
      },
      {
        title: "Дата создания",
        field: "created_at",
        headerSort: true,
        width: 200
      }],
      "en": [{
        title: "Name",
        field: "full_name",
        headerSort: true,
        width: 250,
      },
      {
        title: "Email",
        field: "email",
        headerSort: true,
        width: 200
      },
      {
        title: "Phone",
        field: "phone",
        headerSort: true,
        width: 150
      },
      {
        title: "Position",
        field: "position",
        headerSort: true,
        width: 200
      },
      {
        title: "Created at",
        field: "created_at",
        headerSort: true,
        width: 200
      }]
    }
    return columns
  }

  convertToUrlParams(filters) {
    let query_filters = {}
    filters.forEach(el => {
      if (el["value"].length)
        query_filters[el["field"]] = el["value"];
    })

    return new URLSearchParams(query_filters);
  }

  getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else if (target.getAttribute("data-selected")) {
      return JSON.parse(target.getAttribute("data-selected"));
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

  getSelectCollectionTexts(target) {
    if (target.choices) {
      return target.choices.getValue().map(function (el) { return el.label });
    } else if (target.getAttribute("data-selected")) {
      let selected = Array.apply(null, JSON.parse(target.getAttribute("data-selected")));
      return Array.apply(null, JSON.parse(target.getAttribute("data-options"))).filter(function (el) { return selected.includes(el.value) }).map(function (el) { return el.label; });
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.text; });
    }
  }

}
