import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "paymentStatementsTotalText": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (table)
      return table.getAttribute("data-total-text")
  },
  "paymentStatementsTotalSalary": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-salary");
    return total_html;
  },
  "paymentStatementsTotalAttendanceRate": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-attendance-rate");
    return total_html;
  },
  "paymentStatementsTotalAttendanceBonus": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-attendance-bonus");
    return total_html;
  },
  "paymentStatementsTotalBonus": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-bonus");
    return total_html;
  },
  "paymentStatementsTotalRewards": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-rewards");
    return total_html;
  },
  "paymentStatementsTotalFines": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-fines");
    return total_html;
  },
  "paymentStatementsTotalAmount": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-amount");
    return total_html;
  },
  "paymentStatementsTotalPaid": function (values, data, calcParams) {
    const table = document.getElementById("payment_statements_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-paid");
    return total_html;
  }
});


export default class extends ApplicationController {
  static targets = [ "sidebar_table", "index_table",
    "search", "department_ids"
  ]

  connect() {
    if (this.hasSidebar_tableTarget) {
      const content_url = this.sidebar_tableTarget.getAttribute("data-table-load-url");
      this.createSidebarTable(content_url);
    }

    if (this.hasIndex_tableTarget) {
      const content_url = this.index_tableTarget.getAttribute("data-table-load-url");
      this.createIndexTable(content_url);
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = [];

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDepartment_idsTarget) {
      filters.push({
        field: "department_ids",
        type: "=",
        value: this.department_idsTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }

  async createIndexTable(url) {
    const table_target = this.index_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = table_target.getAttribute("data-sort-column");
    const sort_dir = table_target.getAttribute("data-sort-dir");

    let table_props = {
      persistenceID: "payment_statements_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      pagination: "remote",
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      ajaxResponse:function(url, params, response){
        const table = document.getElementById("payment_statements_table")
        table.setAttribute("data-total-salary", response.total_salary);
        table.setAttribute("data-total-attendance-rate", response.total_attendance_rate);
        table.setAttribute("data-total-attendance-bonus", response.total_attendance_bonus);
        table.setAttribute("data-total-bonus", response.total_bonus);
        table.setAttribute("data-total-rewards", response.total_rewards);
        table.setAttribute("data-total-fines", response.total_fines);
        table.setAttribute("data-total-amount", response.total_amount);
        table.setAttribute("data-total-paid", response.total_paid);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  // create sidebar
  async createSidebarTable(url) {
    const table_target = this.sidebar_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const per_page = table_target.getAttribute("data-per-page");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "payment_statements_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }


}