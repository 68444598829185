import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table", "search", "department_ids"]


  connect() {
    // Main table
    if (this.hasIndex_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }


  filterTable() {
    this.setFilterToTable();
  }

  // Main table filters
  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = []

    // let search_data = []
    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDepartment_idsTarget) {
      filters.push({
        field: "department_ids",
        type: "=",
        value: this.department_idsTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.index_tableTarget;
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let table_props = {
      persistenceID: "attendance_users_table",
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: false
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


}