// import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";
import zxcvbn from "zxcvbn";

export default class extends ApplicationController {
  static targets = ["strength", "password", "btn"]

  check_password(event) {
    const pwd_input = this.passwordTarget;
    const strength = this.strengthTarget;
    const value = pwd_input.value;
    const result = zxcvbn(value);

    strength.className = strength.className.replace(/password-strength--./g, 'password-strength--' + (result.score + 1));

    if (this.hasBtnTarget) {
      const change_pwd_btn = this.btnTarget;
      if (result.score >= 3) {
        change_pwd_btn.disabled = false;
      } else {
        change_pwd_btn.disabled = true;
      }
    }
  }

}