import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "motivationPaymentsTotalsText": function (values, data, calcParams) {
    const table = document.getElementById("motivation_payments_table")
    if (table)
      return table.getAttribute("data-total-text")
  },

  "motivationPaymentsTotalsValues": function (values, data, calcParams) {
    const table = document.getElementById("motivation_payments_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total");
    return total_html;
  }
});

export default class extends ApplicationController {
  static targets = ["index_table", "sidebar_table",
    "search", "department_ids",
    "currencies_radio_template", "modal_currencies_radio", "modal_currency_text"]


  connect() {
    // Main table
    if (this.hasSidebar_tableTarget) {
      const content_url = this.sidebar_tableTarget.getAttribute("data-table-load-url");
      this.createSidebarTable(content_url);
    }

    if (this.hasIndex_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);

      if (this.data.get("open-modal") == "1") {
        this.openLayoutModalMD(this.data.get("add-new-url"));
      }
    }
  }


  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      const rowId = data.id;
      const rowAction = data.row_action;
      const rowData = data.data;
      const total = data.total;

      // update table data
      this.updateTable(rowId, rowAction, rowData, total);

      window.layout_modal_md.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    window.layout_modal_md.setContent(data.form_content)
  }

  onIsConfimedChange(event){
    event.currentTarget.disabled = true;
    let url = event.currentTarget.getAttribute("data-url");
    let data = { "motivation_payment": { "is_confirmed": event.currentTarget.checked ? "true" : "false" }}
    this.updateIsConfirmedStatus(url, data, event.currentTarget);
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }


  updateCurrencies(event) {
    let currencies = event.currentTarget.getAttribute("data-account-currencies");
    let disabled_currency = event.currentTarget.getAttribute("data-account-disabled-currency");
    let li_template = this.currencies_radio_templateTarget.innerHTML;
    let currencies_ul = this.modal_currencies_radioTarget;

    currencies_ul.innerHTML = "";
    if (currencies) {
      currencies.split(",").forEach(el => {
        let li = document.createElement("li");
        let currency_class = (el == disabled_currency ? "disabled" : "")
        let li_html = li_template.replace(/AMOUNT_CURRENCY_CODE/g, el);
        li_html = li_html.replace(/AMOUNT_CURRENCY_CLASS/g, currency_class);

        li.classList.add("nav-item");
        li.innerHTML = li_html;
        currencies_ul.appendChild(li);
      });

      let currency = currencies_ul.querySelector("li:first-child input[type=radio]");
      currency.checked = true;

      let cv = currency.getAttribute("value");

      this.updateInputCurrencies(cv);
    }
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");

    this.updateInputCurrencies(currency);
  }

  updateInputCurrencies(currency) {
    this.modal_currency_textTargets.forEach(el => {
      el.innerHTML = currency;
    })
  }



  filterTable() {
    this.setFilterToTable();
  }


  // Main table filters
  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDepartment_idsTarget) {
      filters.push({
        field: "department_ids",
        type: "=",
        value: this.department_idsTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  async updateIsConfirmedStatus(url, data, element) {
    fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          content = JSON.parse(content);

          window.vNotify.success({ text: content.notification_message, title: content.notification_title });
          this.updateTable(content.id, content.row_action, content.data, null);
        })
      } else {
        response.text().then((content) => {
          content = JSON.parse(content);
          window.vNotify.error({ text: content.notification_message, title: content.notification_title });

          element.disabled = false;
          element.checked = !element.checked;
        })
      }
    });
  }


  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.index_tableTarget;
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let table_props = {
      persistenceID: "motivation_payments_table",
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      rowFormatter: function (row) {
        const data = row.getData();
        if (data.status_color) {
          row.getElement().className = row.getElement().className.replace(/(\s|^)row-\S+/g, '');
          row.getElement().classList.add("row-" + data.status_color);
        }
      },
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("motivation_payments_table")
        table.setAttribute("data-total", response.total);

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async updateTable(rowId, rowAction, rowData, total) {
    const mainTable = document.getElementById("motivation_payments_table");
    const mainTabulator = mainTable.tabulator;

    if (total)
      mainTable.setAttribute("data-total",  total);

    switch (rowAction) {
      case "add":
        // mainTabulator.addRow(rowData, true);
        // mainTabulator.scrollToRow(rowId, "top", false);
        // break;
      case "update":
        mainTabulator.updateRow(rowId, rowData);
        break;
      case "delete":
        mainTabulator.deleteRow(rowId);
        break;
    }

  }

  // create sidebar
  async createSidebarTable(url) {
    const table_target = this.sidebar_tableTarget;
    const columns = table_target.getAttribute("data-table-columns");
    const per_page = table_target.getAttribute("data-per-page");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "motivation_payments_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }

}