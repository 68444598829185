import { ApplicationController } from "../application_controller";
import BSN from "bootstrap.native";
import { getMetaValue } from "helpers";

export default class extends ApplicationController {
  static targets = [
    "payroll_division_template",
    "payroll_divisions_tbody",
    "payroll_divisions_table"
  ]

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalLG(url);
  }

  addNewDivisionRow(event) {
    event.preventDefault();
    const template_row = this.payroll_division_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.payroll_divisions_tbodyTarget.insertAdjacentHTML('beforeend', template_row)
    this.reindexFormTableRows()
  }

  removeNewDivisionRow(event) {
    event.preventDefault();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      tr.remove();
      this.reindexFormTableRows()
    }
  }

  //
  // Private
  //

  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = this.payroll_divisions_tableTarget
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

}