import Tabulator from 'tabulator-tables';
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["tabulator_table"]

  initialize() {
    if (this.hasTabulator_tableTarget) {
      let data = this.data.get("tabulator-datarows");
      // let table = this.setTabulatorTable();
      let table = this.groupTable();
      table.setData(data)
    }
  }

  groupTable() {
    const columns = this.data.get("table-columns");
    let table = new Tabulator(this.tabulator_tableTarget, {
      columns: JSON.parse(columns),
      height: "100%",
      // dataTree: true,
      // dataTreeStartExpanded: true,
      layout: "fitColumns",
      resizableRows: true
    });

    return table;
  }

  showModalForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModal(url);
  }
}
