import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table", "mapping_dropdown", "mapping_checkbox",
    "search"]


  connect() {
    // Main table
    if (this.hasIndex_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  // update partner deal tr
  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      const rowId = data.id;
      const rowAction = data.row_action;
      const rowData = data.data;

      // update table data
      this.updateTable(rowId, rowAction, rowData);

      window.layout_modal_xl.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_xl.setContent(data.form_content)
  }


  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingReload(event) {
    event.preventDefault();
    this.setFilterToTable(true);
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  filterTable() {
    this.setFilterToTable(false);
  }


  // Main table filters
  setFilterToTable(is_mapping) {
    let table = this.index_tableTarget.tabulator;
    let filters = []
    // if is_mapping
    if (is_mapping) {
      let columns = []
      this.mapping_checkboxTargets.forEach((checkbox) => {
        if (checkbox.checked) columns.push(checkbox.value)
      });
      filters.push({
        field: "view_columns",
        type: "=",
        value: columns
      })
    }

    // let search_data = []
    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.index_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 220) + "px",
      persistenceID: "partner_deals_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // headerSort: false,
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoader: true,
      // ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.deals__status) {
          row.getElement().classList.add("row-" + data.deals__status)
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  //
  // update partner deals tabulator
  //
  async updateTable(rowId, rowAction, rowData) {
    const mainTable = document.getElementById("partner_deals_table");
    const mainTabulator = mainTable.tabulator;

    switch (rowAction) {
      case "add":
        mainTabulator.addRow(rowData, true);
        mainTabulator.scrollToRow(rowId, "top", false);
        break;
      case "update":
        mainTabulator.updateRow(rowId, rowData);
        break;
      case "delete":
        mainTabulator.deleteRow(rowId);
        break;
    }

  }


}