import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["attendance_tables", "attendace_month_table",
     "period", "period_start_at", "period_end_at"]


  initialize() {
    if (this.hasAttendace_month_tableTarget) {
      this.attendace_month_tableTargets.forEach(element_target =>{

        let data = element_target.getAttribute("data-tabulator-datarows");
        let table = this.attendanceTabulatorTable(element_target);
        table.setData(data)
      })

    }
  }


  attendanceTabulatorTable(target) {
    const columns = target.getAttribute("data-table-columns");
    let table = new Tabulator(target, {
      columns: JSON.parse(columns),
      height: "100%",
      dataTree: true,
      dataTreeStartExpanded: true,
      layout: "fitColumns",
      resizableRows: true
    });

    return table;
  }

  updateAttendanceTables(event){
    if (this.hasAttendance_tablesTarget) {

      const url = this.attendance_tablesTarget.getAttribute("data-table-load-url");
      let start_at = this.period_start_atTarget.getAttribute("value");
      let end_at   = this.period_end_atTarget.getAttribute("value");
      fetch(url + "?start_at=" + start_at + "&end_at=" + end_at, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'same-origin'
      }).then((response) => {
        if (response.status == 200) {
          response.text().then((content) => {
            this.attendance_tablesTarget.innerHTML = JSON.parse(content)["form_content"];
          })
        }
      });
    }
  }
}