import BSN from "bootstrap.native";
import Tabulator from 'tabulator-tables';
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["table", "search", "sidebar_table", "sidebar_sort",
    "sidebar_current_sort", "sidebar_current_filter",
    "sidebar_partner_responsible_ids", "sidebar_partner_owner_ids",
    "sidebar_filter", "mapping_dropdown", "mapping_checkbox",
    "mapping_fields", "partner_owner_ids", "partners_responsible_ids",
    "group_edit_button", "fit_group_edit_table"]

  connect() {
    if (this.hasTableTarget) {
      this.createTable();
    }

    if (this.hasSidebar_tableTarget) {
      this.createSidebarTable();
    }

    if (this.hasFit_group_edit_tableTarget) {
      this.createEditListTable();
    }

  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  showModalForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModalXL(url);
  }

  groupEditPartner(event) {
    event.preventDefault();
    const tabulator = this.tableTarget.tabulator;
    // const selectedRows = tabulator.getSelectedRows();
    const selectedData = tabulator.getSelectedData();
    if (selectedData.length > 0) {
      let ids = []
      for (let i = 0; i < selectedData.length; i++) {
        let row = selectedData[i];
        if (Array.isArray(row.id)) ids.push(row.id[0])
        else ids.push(row.id)
      }
      const url = event.currentTarget.getAttribute("data-url");
      this.openGruopLayoutModalXL(url, ids);
    }
  }


  sidebarSortClick(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let sidebar_table = this.sidebar_tableTarget.tabulator;

    if (sort_dir == "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    sidebar_table.setSort(sort_column, sort_dir);


    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
    });
    sort_target.classList.add("active");

    let new_html = sort_target.dataset.sortColumnName + "&nbsp; <i class='fa-sort-alpha-" + (sort_dir == "asc" ? "down" : "up") + " fas sort_dir'></i>";
    this.sidebar_current_sortTarget.innerHTML = new_html;
    event.target.innerHTML = new_html;
  }


  filterTable() {
    this.setFilterToTable();
  }

  filterSidebarTable() {
    this.setFilterToSidebarTable();
  }

  setFilterToTable() {
    let filters = this.getPageFilters();

    if (filters.length) {
      let table = this.tableTarget.tabulator;
      table.setFilter(filters);

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })

    }

    if (this.hasPartner_owner_idsTarget) {
      filters.push({
        field: "partner_owner_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.partner_owner_idsTarget)
      })
    }

    if (this.hasPartners_responsible_idsTarget) {
      filters.push({
        field: "partners_responsible_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.partners_responsible_idsTarget)
      })
    }

    return filters;
  }

  setSidebarCurrentFilter() {
    let owner_texts = ""
    let responsible_texts = ""
    if (this.hasSidebar_partner_owner_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_partner_owner_idsTarget);
      const fieldContainer = this.sidebar_partner_owner_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) owner_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_partner_responsible_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_partner_responsible_idsTarget);
      const fieldContainer = this.sidebar_partner_responsible_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) responsible_texts = column_name + ": &nbsp;" + items + "<br/>"
    }
    const current_filter = this.sidebar_current_filterTarget;
    current_filter.innerHTML = owner_texts + responsible_texts;
  }


  setFilterToSidebarTable() {
    let filters = this.getSidebarPageFilters();

    if (filters.length) {
      let table = this.sidebar_tableTarget.tabulator;

      table.setFilter(filters);
      this.setSidebarCurrentFilter();
      // close drop down
      if (this.hasSidebar_filterTarget) {
        this.sidebar_filterTarget.classList.remove('show');
      }

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  getSidebarPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasSidebar_partner_owner_idsTarget) {
      filters.push({
        field: "partner_owner_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_partner_owner_idsTarget)
      })
    }

    if (this.hasSidebar_partner_responsible_idsTarget) {
      filters.push({
        field: "partners_responsible_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_partner_responsible_idsTarget)
      })
    }
    return filters;
  }


  async createTable() {
    const url = this.data.get("table-load-url");
    const columns = this.data.get("table-columns");
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const table_target = this.tableTarget;
    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    let edit_button;

    if (this.hasGroup_edit_buttonTarget)
      edit_button = this.group_edit_buttonTarget;

    let table_props = {
      persistenceID: "fit_main_table",
      height: (height - 150) + "px",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getPageFilters(),

      // tree table
      dataTree: true,

      rowFormatter: function (row) {
        const data = row.getData();
        if (data.status) {
          row.getElement().classList.add("row-" + data.status)
        }
      },
      rowSelectionChanged: function (data, rows) {
        if (edit_button) {
          if (data.length > 0) edit_button.classList.remove("disabled")
          else edit_button.classList.add("disabled")
        }
      }
      // columnResized: function (column) {
      //   const tTable = column.getTable();
      //   tTable.redraw();
      // },
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  // Show Partner
  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }


  async createSidebarTable() {
    const url = this.data.get("table-load-url");
    const columns = this.data.get("table-columns");
    // const selected_row = this.data.get("selected-row");
    const table_target = this.sidebar_tableTarget;
    // const partner_content = document.getElementById("content");

    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "fit_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 50,
      initialFilter: this.getSidebarPageFilters(),
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }

    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);

    this.setSidebarCurrentFilter();
  }

  createEditListTable() {
    const table_target = this.fit_group_edit_tableTarget;
    const tabledata = JSON.parse(this.data.get("table-data"));
    const columns = JSON.parse(this.data.get("table-columns"));
    let table_props = {
      columns: columns,
      data: tabledata,
      layout: "fitDataFill",
      resizableColumns: false,
      headerSort: false,
      height: "600px"
    }
    table_target.tabulator = new Tabulator(table_target, table_props);
    // table_target.tabulator.setLocale(super.appLocale);
  }


  convertToUrlParams(filters) {
    let query_filters = {}
    filters.forEach(el => {
      if (el["value"].length)
        query_filters[el["field"]] = el["value"];
    })

    return new URLSearchParams(query_filters);
  }

  getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else if (target.getAttribute("data-selected")) {
      return JSON.parse(target.getAttribute("data-selected"));
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

  getSelectCollectionTexts(target) {
    if (target.choices) {
      return target.choices.getValue().map(function (el) { return el.label });
    } else if (target.getAttribute("data-selected")) {
      let selected = Array.apply(null, JSON.parse(target.getAttribute("data-selected")));
      return Array.apply(null, JSON.parse(target.getAttribute("data-options"))).filter(function (el) { return selected.includes(el.value) }).map(function (el) { return el.label; });
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.text; });
    }
  }

}
