import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = ["rewards_index_table", "fines_index_table",
    "rewards_period", "rewards_period_start_at", "rewards_period_end_at",
    "fines_period", "fines_period_start_at", "fines_period_end_at"]

  connect() {
    if (this.hasRewards_index_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createRewardsTable(content_url);
    }

    if (this.hasFines_index_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createFinesTable(content_url);
    }

  }

  rewardsTableFilter() {
    this.setRewardsFilterToTable();
  }

  finesTableFilter() {
    this.setFinesFilterToTable();
  }

  setRewardsFilterToTable() {
    let table = this.rewards_index_tableTarget.tabulator;
    let filters = []

    if (this.hasRewards_period_start_atTarget) {
      filters.push({
        field: "start_at",
        type: "=",
        value: this.rewards_period_start_atTarget.value
      })
    }

    if (this.hasRewards_period_end_atTarget) {
      filters.push({
        field: "end_at",
        type: "=",
        value: this.rewards_period_end_atTarget.value
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }

  setFinesFilterToTable() {
    let table = this.fines_index_tableTarget.tabulator;
    let filters = []

    if (this.hasFines_period_start_atTarget) {
      filters.push({
        field: "start_at",
        type: "=",
        value: this.fines_period_start_atTarget.value
      })
    }

    if (this.hasFines_period_end_atTarget) {
      filters.push({
        field: "end_at",
        type: "=",
        value: this.fines_period_end_atTarget.value
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }



  async createRewardsTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.rewards_index_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 220) + "px",
      persistenceID: "motivation_payments_rewards_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // headerSort: false,
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.status_color) {
          row.getElement().classList.add("row-" + data.status_color)
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  async createFinesTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.fines_index_tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 220) + "px",
      persistenceID: "motivation_payments_fines_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // headerSort: false,
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.status_color) {
          row.getElement().classList.add("row-" + data.status_color)
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

}